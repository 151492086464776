<template>
<div>
    <!-- header new -->
    <LandingHeader />

    <!-- hero section -->
    <OtherHero heading="Bill Of Rights" />

    <div class="other-container">
        <div class="content-box">
            <h2 class="pb-4">
                <strong>Parents' Bill of Rights for Data Privacy and Security</strong>
            </h2>

            <p>
                In accordance with state and federal laws, and the
                <strong>Family Educational Rights and Privacy Act (FERPA) (20 U.S.C. § 1232g; 34 CFR Part 99),</strong>
                and the <strong>NYSED</strong> &#8211;
                <strong>Personally Identifiable Information Part 121 Terms</strong>,
                <strong>Lilo Consulting, LLC., Sync Grades </strong>
                protects the confidentiality of information about
                your child that identifies him or her.
            </p>

            <p>
                This includes student-specific data, known as
                “<strong>personally identifiable information</strong>.” As a parent of a child attending a New York
                City public school, within the NYCDOE, you have
                rights regarding the privacy and security of your
                child's personally identifiable information and
                data, under New York state's education law.
            </p>
            <p>
                Please note that
                <strong>Lilo Consulting, LLC., Sync Grades </strong>
                <strong> can not and will not sell or release </strong>your child's personally identifiable information
                for any commercial purposes.
            </p>
            <p>
                As a parent of a child under 18 years of age, you
                have the right to inspect and review the complete
                contents of your child's education records.
            </p>
            <p>
                Below, please find a resource link provided by the
                NYCDOE, which outlines your privacy rights
                provided under federal law.
            </p>
            <p>
                <strong>NYCDOE Parents'
                    <a
                        href="https://www.schools.nyc.gov/school-life/policies-for-all/parents-bill-of-rights"
                    >Bill of Rights</a>.</strong>
            </p>
            <p>
                Also, below please find a complete<a
                    href="http://www.p12.nysed.gov/irs/sirs/documentation/NYSEDstudentData.xlsx"
                >
                    list of all of the types of student data
                    provided by the NYSED (Open external link)</a>.
            </p>
            <p>
                <strong>Lilo Consulting, LLC., Sync Grades</strong>
                has established safeguards to protect your child's
                personally identifiable data when it is stored or
                transferred. Our security and safeguards will
                always meet or exceed industry standards and best
                practices, such as encryption, firewalls and
                password protection.
            </p>
            <p>
                As a parent, it is within your right to make
                complaints about any possible security breaches of
                student data and to have such complaints addressed
                immediately.
            </p>
            <p>
                Complaints to
                <strong>Lilo Consulting, LLC., Sync Grades </strong> should be directed in writing to:
            </p>
            <p>
                <strong>Lilo Consulting, LLC </strong>
                348 Rolling Hills Dr East Stroudsburg, PA 18302
                <strong>
                    <a href="mailto:sales@syncgrades.com">sales@syncgrades.com</a>
                </strong>
            </p>
            <p>
                Complaints to the<strong>
                    NY State Education Department </strong>should be directed in writing to the:
            </p>
            <p>
                <strong>Chief Privacy Officer </strong> New York
                State Education Department 89 Washington Avenue,
                Albany NY 12234 email to
                <a href="mailto:CPO@mail.nysed.gov">CPO@mail.nysed.gov</a>.
            </p>
            <p>
                You may also obtain a copy of this list by writing
                to the Office of Information &amp; Reporting
                Services, New York State Education Department,
                Room 863 EBA, 89 Washington Avenue, Albany, NY
                12234.
            </p>
            <p>
                Complaints to the NYCDOE should be directed via
                email to data-security@schools.nyc.gov or in
                writing to the:
            </p>
            <p>
                <strong>Office of the Chief Information Officer</strong>
                Division of Instructional and Information
                Technology New York City Department of Education
                335 Adams Street, Brooklyn NY 11201
            </p>
        </div>
    </div>

    <!-- footer-section -->
    <LandingFooter />
</div>
</template>

<script>
import LandingHeader from '../components/landing/LandingHeader.vue';
import LandingFooter from '../components/landing/LandingFooter.vue';
import OtherHero from '../components/landing/OtherHero.vue';

export default {
    name: 'BillOfRights',
    components: {
        LandingHeader,
        LandingFooter,
        OtherHero,
    },
};
</script>

<style scoped lang="scss">

.other-container {
  padding: 50px 10px;

  .content-box {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-size: 1.4em;

    p {
      margin-bottom: 20px;
    }
  }
}

</style>
