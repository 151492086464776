var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("LandingHeader"),
      _c("OtherHero", { attrs: { heading: "Bill Of Rights" } }),
      _vm._m(0),
      _c("LandingFooter"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "other-container" }, [
      _c("div", { staticClass: "content-box" }, [
        _c("h2", { staticClass: "pb-4" }, [
          _c("strong", [
            _vm._v("Parents' Bill of Rights for Data Privacy and Security"),
          ]),
        ]),
        _c("p", [
          _vm._v(" In accordance with state and federal laws, and the "),
          _c("strong", [
            _vm._v(
              "Family Educational Rights and Privacy Act (FERPA) (20 U.S.C. § 1232g; 34 CFR Part 99),"
            ),
          ]),
          _vm._v(" and the "),
          _c("strong", [_vm._v("NYSED")]),
          _vm._v(" – "),
          _c("strong", [
            _vm._v("Personally Identifiable Information Part 121 Terms"),
          ]),
          _vm._v(", "),
          _c("strong", [_vm._v("Lilo Consulting, LLC., Sync Grades ")]),
          _vm._v(
            " protects the confidentiality of information about your child that identifies him or her. "
          ),
        ]),
        _c("p", [
          _vm._v(" This includes student-specific data, known as “"),
          _c("strong", [_vm._v("personally identifiable information")]),
          _vm._v(
            ".” As a parent of a child attending a New York City public school, within the NYCDOE, you have rights regarding the privacy and security of your child's personally identifiable information and data, under New York state's education law. "
          ),
        ]),
        _c("p", [
          _vm._v(" Please note that "),
          _c("strong", [_vm._v("Lilo Consulting, LLC., Sync Grades ")]),
          _c("strong", [_vm._v(" can not and will not sell or release ")]),
          _vm._v(
            "your child's personally identifiable information for any commercial purposes. "
          ),
        ]),
        _c("p", [
          _vm._v(
            " As a parent of a child under 18 years of age, you have the right to inspect and review the complete contents of your child's education records. "
          ),
        ]),
        _c("p", [
          _vm._v(
            " Below, please find a resource link provided by the NYCDOE, which outlines your privacy rights provided under federal law. "
          ),
        ]),
        _c("p", [
          _c("strong", [
            _vm._v("NYCDOE Parents' "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.schools.nyc.gov/school-life/policies-for-all/parents-bill-of-rights",
                },
              },
              [_vm._v("Bill of Rights")]
            ),
            _vm._v("."),
          ]),
        ]),
        _c("p", [
          _vm._v(" Also, below please find a complete"),
          _c(
            "a",
            {
              attrs: {
                href: "http://www.p12.nysed.gov/irs/sirs/documentation/NYSEDstudentData.xlsx",
              },
            },
            [
              _vm._v(
                " list of all of the types of student data provided by the NYSED (Open external link)"
              ),
            ]
          ),
          _vm._v(". "),
        ]),
        _c("p", [
          _c("strong", [_vm._v("Lilo Consulting, LLC., Sync Grades")]),
          _vm._v(
            " has established safeguards to protect your child's personally identifiable data when it is stored or transferred. Our security and safeguards will always meet or exceed industry standards and best practices, such as encryption, firewalls and password protection. "
          ),
        ]),
        _c("p", [
          _vm._v(
            " As a parent, it is within your right to make complaints about any possible security breaches of student data and to have such complaints addressed immediately. "
          ),
        ]),
        _c("p", [
          _vm._v(" Complaints to "),
          _c("strong", [_vm._v("Lilo Consulting, LLC., Sync Grades ")]),
          _vm._v(" should be directed in writing to: "),
        ]),
        _c("p", [
          _c("strong", [_vm._v("Lilo Consulting, LLC ")]),
          _vm._v(" 348 Rolling Hills Dr East Stroudsburg, PA 18302 "),
          _c("strong", [
            _c("a", { attrs: { href: "mailto:sales@syncgrades.com" } }, [
              _vm._v("sales@syncgrades.com"),
            ]),
          ]),
        ]),
        _c("p", [
          _vm._v(" Complaints to the"),
          _c("strong", [_vm._v(" NY State Education Department ")]),
          _vm._v("should be directed in writing to the: "),
        ]),
        _c("p", [
          _c("strong", [_vm._v("Chief Privacy Officer ")]),
          _vm._v(
            " New York State Education Department 89 Washington Avenue, Albany NY 12234 email to "
          ),
          _c("a", { attrs: { href: "mailto:CPO@mail.nysed.gov" } }, [
            _vm._v("CPO@mail.nysed.gov"),
          ]),
          _vm._v(". "),
        ]),
        _c("p", [
          _vm._v(
            " You may also obtain a copy of this list by writing to the Office of Information & Reporting Services, New York State Education Department, Room 863 EBA, 89 Washington Avenue, Albany, NY 12234. "
          ),
        ]),
        _c("p", [
          _vm._v(
            " Complaints to the NYCDOE should be directed via email to data-security@schools.nyc.gov or in writing to the: "
          ),
        ]),
        _c("p", [
          _c("strong", [_vm._v("Office of the Chief Information Officer")]),
          _vm._v(
            " Division of Instructional and Information Technology New York City Department of Education 335 Adams Street, Brooklyn NY 11201 "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }