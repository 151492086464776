import { render, staticRenderFns } from "./BillOfRights.vue?vue&type=template&id=d4d078ba&scoped=true"
import script from "./BillOfRights.vue?vue&type=script&lang=js"
export * from "./BillOfRights.vue?vue&type=script&lang=js"
import style0 from "./BillOfRights.vue?vue&type=style&index=0&id=d4d078ba&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4d078ba",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/apps/SyncGrades.Web/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d4d078ba')) {
      api.createRecord('d4d078ba', component.options)
    } else {
      api.reload('d4d078ba', component.options)
    }
    module.hot.accept("./BillOfRights.vue?vue&type=template&id=d4d078ba&scoped=true", function () {
      api.rerender('d4d078ba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/rootComponents/BillOfRights.vue"
export default component.exports